import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import public_contents from './operator.json';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setResetSuccess } = useAuth();

    const [content, setContent] = useState({ name: '', tel: '', img: '', email: '', adds: '' });
    const contents = React.useState(public_contents);

    const [formData, setFormData] = useState({
        newPassword: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');

    const handleSuccess = () => {
        setResetSuccess(true);
        navigate(`/reset-password-success/?operator=${content.name}`, { replace: true });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.newPassword !== formData.confirmPassword) {
            setError('Passwords do not match');
            return;
        } else {
            if (areAllValidationsTrue) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/ms/v1/update_password/?token=${token}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            token: token,
                            password: formData.confirmPassword,
                        }),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();

                    if (data) {
                        if (data.meta.response_code === 20000) {
                            handleSuccess();
                        } else {
                            alert(`${data.meta.response_desc}`);
                        }
                    }

                } catch (error) {
                    console.error('There was an error!', error);
                }
            } else {
                setError('something went wrong');
            }
        }
        setFormData({
            newPassword: '',
            confirmPassword: ''
        });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'newPassword') {
            validatePassword(value);
        }
    };

    const togglePasswordVisibility = () => {
        setFormData({ ...formData, showPassword: !formData.showPassword });
    };

    const [passwordValidations, setPasswordValidations] = useState({
        minLength: false,
        hasNumber: false,
        hasUpper: false,
        hasLower: false,
    });

    const validatePassword = (password) => {
        setPasswordValidations({
            minLength: password.length >= 8,
            hasNumber: /[0-9]/.test(password),
            hasUpper: /[A-Z]/.test(password),
            hasLower: /[a-z]/.test(password),
        });
    };
    const areAllValidationsTrue = Object.values(passwordValidations).every(value => value);

    function useQuery() {
        return new URLSearchParams(location.search);
    }

    const query = useQuery();
    const operator = query.get('operator');
    const token = query.get('token');

    useEffect(() => {
        if (operator === 'charge24') {
            const newContent = contents[0][0];
            setContent(newContent);
        } else if (operator === 'ample') {
            const newContent = contents[0][1];
            if (content.name !== newContent.name) {
                setContent(newContent);
            }
        }
    }, [operator]);

    return (
        <>
            <Helmet>
                <title>Reset Password</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@400&subset=thai&display=swap" rel="stylesheet" />
            </Helmet>

            <div className="bg-white max-w-lg mx-auto my-12 p-8 border border-gray-200">
                <div className="flex justify-center">
                    <img src={content.img} alt="Logo" width="180" height="118" />
                </div>
                <div className="w-full h-px max-w-6xl mx-auto my-8" style={{ backgroundColor: '#D9D9D9' }}></div>
                <div className="text-gray-800 text-center text-base	">
                    <div className="font-bold text-2xl mb-4"> Reset Password </div>

                </div>

                <form onSubmit={handleSubmit} >
                    <div>
                        <label htmlFor="newPassword" className="text-base">
                        Password
                        </label>
                        <input
                            type={formData.showPassword ? 'text' : 'password'}
                            id="newPassword"
                            name="newPassword"
                            value={formData.newPassword}
                            className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            required
                            onChange={handleInputChange}
                            placeholder="Password"
                        />
                    </div>
                    <div>
                        <ul className="list-disc pl-8 border-dashed border-2 bg-zinc-300 my-6 py-3.5 px-3 rounded-md">
                            <li className={`${passwordValidations.minLength ? 'text-dark' : 'text-red-600'} mb-2`}>At least 8 characters</li>
                            <li className={`${passwordValidations.hasNumber ? 'text-dark' : 'text-red-600'} mb-2`}>At least one number</li>
                            <li className={`${passwordValidations.hasUpper ? 'text-dark' : 'text-red-600'} mb-2`}>At least one uppercase letter</li>
                            <li className={`${passwordValidations.hasLower ? 'text-dark' : 'text-red-600'} mb-2`}>At least one lowercase letter</li>
                        </ul>
                    </div>
                    <div>
                        <label htmlFor="newPassword" className="text-base">
                        Confirm password
                        </label>
                        <div className="relative">
                            <input
                                type={formData.showPassword ? 'text' : 'password'}
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirm password"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                            >
                                {formData.showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>

                    </div>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    <button
                        type="submit"
                        className="w-full bg-gradient-to-r from-blue-start to-blue-end text-white font-bold py-2 px-4 rounded-button hover:bg-gradient-to-br mt-10"
                        style={{ padding: '8px, 16px, 8px, 16px' }}
                    >
                        Submit 
                    </button>
                </form>
            </div >
        </>
    );
};

export default ResetPassword;
