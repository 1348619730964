import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import public_contents from './operator.json';
import { useNavigate } from 'react-router-dom';

const Verify = () => {
  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const operator = query.get('operator');
  const token = query.get('token');

  const navigate = useNavigate();

  const [content, setContent] = useState({ name: '', tel: '', img: '', email: '', adds: '' });
  const contents = React.useState(public_contents);

  useEffect(() => {
    if (operator === 'charge24') {
      const newContent = contents[0][0];
      setContent(newContent);
    } else if (operator === 'ample') {
      const newContent = contents[0][1];
      if (content.name !== newContent.name) {
        setContent(newContent);
      }
    }
  }, [operator]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/ms/v1/credentials/token_verify/?operator=${operator}&token=${token}`)
      .then(response => {
        if (response.headers.get('content-type')?.includes('application/json')) {
          return response.json();
        }
        throw new Error('Received non-JSON response from server');
      })
      .then((data) => {
        console.log(data)
        if (data) {
          if (data.meta.response_code === 20000) {
            navigate(`/verify-success/?operator=${operator}&token=${token}`);
          } else {
            navigate(`/`);
          }
        } else {
          navigate(`/`);
        }
      }).catch(error => console.error(error));
  }, []);

  return (
    <>
    </>
  );
};

export default Verify;
