import './App.css';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <div className="App">
      <Helmet>
        <title>404 Not Found </title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@400&subset=thai&display=swap" rel="stylesheet" />

      </Helmet>

      <div className="bg-white max-w-lg mx-auto my-12 p-8 border border-gray-200">
        <div className="font-bold text-center text-3xl"> 404 </div>
        <div className="font-bold text-center text-xl"> Not Found </div>
        <div className="w-full h-px max-w-6xl mx-auto my-8" style={{ backgroundColor: '#D9D9D9' }}></div>
        <div className="text-gray-800 text-center text-base	">
          <div className="font-bold text-xl">  There was an error!  </div>
          <br />
          Access to this page may be incorrect.  <br />
          Please close and re-enter.  <br />
        </div>

      </div>
    </div>
  );
}

export default Home;
