// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const setResetSuccess = (status) => {
    setPasswordResetSuccess(status);
  };

  return (
    <AuthContext.Provider value={{ passwordResetSuccess, setResetSuccess }}>
      {children}
    </AuthContext.Provider>
  );
};
