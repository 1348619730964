import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Verify from './verify';
import Home from './home'; //
import ResetPassword from './resetPassword';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import VerifySuccess from './verifySuccess';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/verify-success" element={<VerifySuccess />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/reset-password-success" element={
            // <ProtectedRoute>
              <ResetPasswordSuccess />
            // </ProtectedRoute>
          } />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
