import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import public_contents from './operator.json';
import { Navigate } from 'react-router-dom';

const VerifySuccess = () => {
  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const operator = query.get('operator');
  const token = query.get('token');

  const [content, setContent] = useState({ name: '', company: '', tel: '', img: '', email: '', adds: '' });
  const contents = React.useState(public_contents);

  useEffect(() => {
    if (operator === 'charge24') {
      const newContent = contents[0][0];
      setContent(newContent);
    } else if (operator === 'ample') {
      const newContent = contents[0][1];
      if (content.name !== newContent.name) {
        setContent(newContent);
      }
    }
  }, [operator]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/ms/v1/verify_email/?operator=${operator}&token=${token}`)
      .then(response => response.json())
      .then((data) => {
      }).catch(error => console.error(error));
  }, []);

  return (
    <>
      <Helmet>
        <title>Email Verification Success</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@400&subset=thai&display=swap" rel="stylesheet" />
      </Helmet>

      <div className="bg-white max-w-lg mx-auto my-12 p-8 border border-gray-200">
        <div className="flex justify-center">
          <img src={content.img} alt="Logo" width="180" height="118" />
        </div>
        <div className="w-full h-px max-w-6xl mx-auto my-8" style={{ backgroundColor: '#D9D9D9' }}></div>
        <div className="text-gray-800 text-center text-base	">
          <div className="font-bold text-2xl">Welcome </div>
          <br />
          We appreciate having been a part of your journey <br />
          To keep up with our latest news, <br />
          Please follow our <a href={`${content.facebook}`} className="text-blue-600 hover:text-blue-800"> Facebook </a> page
        </div>

        <div className="text-center font-bold text-xl my-6"> You may now close this window <br /> and begin using the application. </div>
        <div className="w-full h-px max-w-6xl mx-auto my-8" style={{ backgroundColor: '#D9D9D9' }}></div>

        <div className="text-center my-4">
          <p className="text-start font-bold text-sm pb-1.5 first-letter-uppercase">
            {content.company}
          </p>
          <p className="text-start text-sm pb-1.5">
            {content.adds}
          </p>

          <div className="flex flex-col items-start sm:flex-row">
            {content.tel !== "-" && (
              <a href={`tel:${content.tel}`} className="text-blue-600 hover:text-blue-800 mx-2">
                <div className='inline-flex items-center'>
                  <FaPhone />
                  <div className='pl-2'>{content.tel}</div>
                </div>
              </a>
            )}
            <a href={`mailto:${content.email}`} className="text-blue-600 hover:text-blue-800 mx-2">
              <div className='inline-flex items-center'>
                <FaEnvelope />
                <div className='pl-2'> {content.email} </div>
              </div>
            </a>
          </div>
        </div>
      </div >
    </>
  );
};

export default VerifySuccess;
